<template>
  <div
    class="bitts-badge"
    :class="{
      [status]: true,
      [size]: true,
    }"
  >
    <badge :status="statusAntd" :text="text">
      <template v-if="!!icon">
        <FontAwesomeIcon
          :class="size === 'small' ? 'text-10' : 'text-12'"
          :icon="icon"
        />
      </template>
    </badge>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Badge } from 'ant-design-vue';
import { computed } from 'vue';

import { BittsIcon } from '../types';

import { ISize, IStatus, Size, Status } from './types';

export interface Props {
  icon?: BittsIcon;
  text?: string | null;
  status?: IStatus;
  size?: ISize;
}

const props = withDefaults(defineProps<Props>(), {
  icon: null,
  text: null,
  status: Status.Neutral,
  size: Size.Medium,
});

/* Used to color the dot */
const statusAntd = computed(() => {
  return (
    {
      success: 'success',
      danger: 'error',
      info: 'processing',
      neutral: 'default',
      warning: 'warning',
      error: 'error',
    } as const
  )[props.status];
});
</script>

<style lang="pcss">
.bitts-badge {
  @apply inline-block;

  .ant-badge {
    @apply px-8 flex items-center rounded-[28px];
    font-family: inherit;
  }
  .ant-badge-status {
    @apply leading-none flex;
  }
  .ant-badge.ant-badge-status .ant-badge-status-dot {
    @apply rounded-full w-4 h-4 top-0 mr-4;
  }
  .ant-badge.ant-badge-status .ant-badge-status-text {
    font-family: inherit;
    @apply ml-4 flex items-center ms-0;
  }
  .ant-scroll-number {
    @apply hidden;
  }
  .ant-scroll-number-custom-component {
    @apply relative transform-none;
  }

  /* Kill the (pretty cool, but currently unwanted!) pulsating "processing" effect */
  .ant-badge-status-processing::after {
    content: none;
  }

  &.medium {
    .ant-badge-status-text {
      @apply text-base;
    }
  }

  &.small {
    .ant-badge {
      @apply py-0;
    }
    .ant-badge-status-dot {
    }
    .ant-badge-status-text {
      @apply text-sm;
    }
  }

  &.success {
    .ant-badge {
      @apply bg-success-background-weak text-success-accent;
    }
    .ant-badge-status-dot {
      @apply bg-success-accent;
    }
    .ant-badge-status-text {
      @apply text-success-text;
    }
  }

  &.danger,
  &.error {
    .ant-badge {
      @apply bg-danger-background-weak text-danger-accent;
    }
    .ant-badge-status-dot {
      @apply bg-danger-accent;
    }
    .ant-badge-status-text {
      @apply text-danger-text;
    }
  }

  &.info {
    .ant-badge {
      @apply bg-info-background-weak text-info-accent;
    }
    .ant-badge-status-dot {
      @apply bg-info-accent;
    }
    .ant-badge-status-text {
      @apply text-info-text;
    }
  }

  &.warning {
    .ant-badge {
      @apply bg-warning-background-weak text-warning-accent;
    }
    .ant-badge-status-dot {
      @apply bg-warning-accent;
    }
    .ant-badge-status-text {
      @apply text-warning-text;
    }
  }

  &.neutral {
    .ant-badge {
      @apply text-neutral-accent;
    }
    .ant-badge-status-dot {
      @apply bg-neutral-accent;
    }
  }
  &.neutral,
  &.background-neutral {
    .ant-badge {
      @apply bg-neutral-background;
    }
    .ant-badge-status-text {
      @apply text-neutral-text;
    }
  }
}
</style>
